import React,{useState,useContext } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserProvider';
import './Nav.css';
function Nav({color}){
	const {user} = useContext(UserContext);
	const [isHover1,setIsHover1]=useState(false);
	const [isHover2,setIsHover2]=useState(false);
	const [navClicked,setNavClicked]=useState(false);
	const [navDetail,setNavDetail]=useState([false,false]);
	const [billing,setBilling]=useState();
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0');
	const day = String(today.getDate()).padStart(2, '0');
	const currentDate = `${year}-${month}-${day}`;
	const handleMouseEnter1=()=>{
		setIsHover1(true);
	}
	const handleMouseLeave1=()=>{
		setIsHover1(false);
	}
	const handleMouseEnter2=()=>{
		setIsHover2(true);
	}
	const handleMouseLeave2=()=>{
		setIsHover2(false);
	}
	useState(()=>{
		axios.get('/checkbill')
		.then(response=>{
			setBilling(response.data.bill);
		})
		.catch(error=>{
			console.error('Error fetching billing data:',error)
		})
	},[user]);
	return(
		<div className='nav_bx'>
			<div className='nav_top'>
				<Link to='/1' className='nav_link'>국어</Link>
				<Link to='/2' className='nav_link'>수학</Link>
				<Link to='/3' className='nav_link'>영어</Link>
				<Link to='/problem/13' className='nav_link'>한국사</Link>
			</div>
			<div className='nav_ct'>
				<Link to='/' className='title' style={{'color':color?color:'#333'}}>기출조각</Link>
				{user.isLogin ? (
   	             <div className='lg'>
						<Link to='/userinfo' className='lg_txt'>{user.username}님</Link>
						<a href='/logout' className='lg_txt'>로그아웃</a>
						<div className='nav_cd'>
							<div className='cr_txt'>{user.due_date>=currentDate?`${billing?'구독권':'회원권'} 이용 중`:''}</div>
						</div>
					</div>
				) :<div className='lg'>
					 <Link to='/login' className='lg_txt'>로그인</Link>
					 <Link to='/signup' className='lg_txt'>회원가입</Link>
				</div>}
				<div className='nav_tt'><Link to='/pricing' className='lg_txt'>비용</Link></div>
				<div className='nav_tt' onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1}>
					<div className='lg_txt'>문제 풀기</div>
					<div className='hv_dpn' style={{'display':isHover1?'flex':'none'}}>
					<Link to='/problem/9' className='dpn_txt'>국어</Link>
						<Link to='/problem/1' className='dpn_txt'>수학</Link>
						<Link to='/problem/5' className='dpn_txt'>영어</Link>
						<Link to='/problem/13' className='dpn_txt'>한국사</Link>
					</div>
				</div>
				<div className='nav_tt' onMouseEnter={handleMouseEnter2} onMouseLeave={handleMouseLeave2}>
					<div className='lg_txt'>게시판</div>
					<div className='hv_dpn' style={{'display':isHover2?'flex':'none'}}>
						<Link to='/board/1' className='dpn_txt'>공지사항</Link>
						<Link to='/board/2' className='dpn_txt'>오류제보</Link>
						<Link to='/board/3' className='dpn_txt'>질문</Link>
						<Link to='/board/4' className='dpn_txt'>칼럼</Link>
					</div>
				</div>
				<div className='nav_tt'><a href='/#help' className='lg_txt'>고객센터</a></div>
				<div className={navClicked?'nav_bar_close':'nav_bar'} onClick={()=>setNavClicked(!navClicked)}/>
				<div className='nav_side' style={{'display':navClicked?'block':'none'}}>
					{user.isLogin?
						<div className='side_tt' style={{'flexDirection':'column','height':'100px'}}>
						 <div className='side_tt'><Link to='/userinfo' className='side_tt'>{user.username}</Link><a href='/logout' className='side_tt'>로그아웃</a></div>
						 <div className='side_tt'>
							 <div><img src='/icon/credit.png' alt='credit' className='nav_credit'/>{user.due_date>=currentDate?`${billing?'구독권':'회원권'} 이용 중`:'구독 정보 없음'}</div>
						 </div>
					 </div>
						:<div className='side_tt'><Link to='/login' className='side_tt'>로그인</Link><Link to='/signup' className='side_tt'>회원가입</Link></div>}
					<Link to='/pricing'className='side_tt'>비용</Link>
					<div className='side_tt' onClick={()=>setNavDetail([!navDetail[0],navDetail[1]])} style={{'background':navDetail[0]?'rgb(230,230,230)':'none'}}>문제 풀기</div>
					<Link to='/problem/9' className='side_tt side_small'style={{'display':navDetail[0]?'flex':'none'}}>국어</Link>
					<Link to='/problem/1' className='side_tt side_small'style={{'display':navDetail[0]?'flex':'none'}}>수학</Link>
					<Link to='/problem/5' className='side_tt side_small'style={{'display':navDetail[0]?'flex':'none'}}>영어</Link>
					<Link to='/problem/13' className='side_tt side_small'style={{'display':navDetail[0]?'flex':'none'}}>한국사</Link>

					<div className='side_tt' onClick={()=>setNavDetail([navDetail[0],!navDetail[1]])} style={{'background':navDetail[1]?'rgb(230,230,230)':'none'}}>게시판</div>
					<Link to='/board/1' className='side_tt side_small'style={{'display':navDetail[1]?'flex':'none'}}>공지사항</Link>
					<Link to='/board/2' className='side_tt side_small'style={{'display':navDetail[1]?'flex':'none'}}>오류제보</Link>
					<Link to='/board/3' className='side_tt side_small'style={{'display':navDetail[1]?'flex':'none'}}>질문</Link>
					<Link to='/board/4' className='side_tt side_small'style={{'display':navDetail[1]?'flex':'none'}}>칼럼</Link>
					<a href='/#help'className='side_tt'>고객센터</a>
				</div>
			</div>
		</div>
	);
}

export default Nav;