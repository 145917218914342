import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import "./Problem.css";
import { UserContext } from "../UserProvider";
import Dropdown from "../component/Dropdown.js";
import Nav from "../component/Nav.js";
import Loading from "../component/Loading.js";
import CheckboxGroup from "../component/CheckboxGroup.js";
import Footer from "../component/Footer";
import axios from "axios";
function Problem() {
  const { id } = useParams();
  const intId = Number(id);
  const [on, setOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const years1 = [
    2024,
    2023,
    2022,
    2021,
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
    2009,
    2008,
    2007,
    2006,
  ];
  const years2 = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016];
  const [solvedlist, setSolvedList] = useState("");
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const currentDate = `${year}-${month}-${day}`;
  const [formData, setFormData] = useState({
    type: id,
    start: 2021,
    end: 2024,
    select: intId <= 4 && intId >= 1 ? "cal" : "media",
    dup: false,
  });
  const [checkedItems, setCheckedItems] = useState({
    all: false,
    pyung: true,
    gyo: false,
    sag: false,
    pol: false,
  });
  const [nameList, setNameList] = useState({
    all: "전체",
    pyung: "평가원",
    gyo: "교육청",
    sag: "사관학교",
    pol: "경찰대",
  });
  const history = useNavigate();
  const { user } = useContext(UserContext);

  const handleChange = (names, values) => {
    setFormData({ ...formData, [names]: values });
  };
  useEffect(
    () => {
      setFormData({
        type: id,
        start: 2021,
        end: 2024,
        select: intId <= 4 && intId >= 1 ? "cal" : "media",
        dup: false,
      });
      if (intId <= 4 && intId >= 1) {
        setCheckedItems({
          all: false,
          pyung: true,
          gyo: false,
          sag: false,
          pol: false,
        });
        setNameList({
          all: "전체",
          pyung: "평가원",
          gyo: "교육청",
          sag: "사관학교",
          pol: "경찰대",
        });
      } else if (intId === 10) {
        setCheckedItems({
          all: true,
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
        });
        setNameList({
          all: "전체",
          1: "독서론",
          2: "인문/예술",
          3: "법/경제",
          4: "과학/기술",
          5: "융합",
        });
      } else if (intId === 11) {
        setCheckedItems({
          all: true,
          6: true,
          7: true,
          8: true,
          9: true,
          10: true,
          11: true,
        });
        setNameList({
          all: "전체",
          6: "현대시",
          7: "고전시가",
          8: "현대소설",
          9: "고전소설",
          10: "극/수필",
          11: "융합",
        });
      }
      if (!(intId >= 1 && intId <= 13)) {
        history("/error");
      }
      setFormData((prevFormData) => ({
        ...prevFormData,
        type: id,
      }));
    },
    [id]
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isAnyChecked = Object.values(checkedItems).some((value) => value);

    if (!isAnyChecked) {
      window.alert("최소한 1개 이상의 체크박스를 선택해 주세요.");
    } else if (user && user.isLogin) {
      if (user.due_date >= currentDate || Number(formData.type)===13) {
        try {
          setIsLoading(true);
          const response = await fetch("/make", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...formData,
              checkbox: checkedItems,
              solvedlist: solvedlist,
            }),
          });
          if (response.ok) {
            if (response.headers.get("Content-Type") === "application/pdf") {
              const blob = await response.blob();
              const url = window.URL.createObjectURL(new Blob([blob]));
              const a = document.createElement("a");
              a.href = url;
              a.download = "problems.pdf";
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
              setIsLoading(false);
              history("/done");
            } else {
              const data = await response.json();
              window.alert(data.alert);
              setIsLoading(false);
              history("/");
            }
          } else {
            history("/error");
          }
        } catch (error) {
          console.error("Network error:", error);
        }
      } else {
        window.alert("구독 기간이 지났습니다. 구독 후 이용해 주세요.");
        history("/pricing");
      }
    } else {
      window.alert("로그인 후 이용해 주세요.");
      history("/login");
    }
  };
  const dupClick = async () => {
    handleChange("dup", !formData.dup);
    setIsLoading(true);
    await axios.get(`/getsolved/${id}`).then((response) => {
      const data = response.data.solved;
      const arr = data.map((item) => item.problems.join(","));
      setSolvedList(arr.join(","));
      setIsLoading(false);
    });
  };
  const txt = [
    "",
    "30문제로 구성된 풀 모의고사. 전체적인 기출 분석, 실전 대비에 용이합니다.",
    "10문제로 구성된 4점(쉬운4점~준킬러) 문제. 준킬러 대비, 시간 단축 연습에 용이합니다.",
    "4문제로 구성된 최고난도 킬러문제. 고난도 문제 풀이 연습, 고득점 대비에 용이합니다.",
    "12문제로 구성된 어려운 3점, 쉬운 4점 문제. 기본기를 다루는데 용이합니다.",
    "28문제 듣기 제외 모의고사 구성. 기출 문제를 골고루 훑어보기 좋습니다.",
    "8문제로 빈칸완성 4문제, 순서/삽입 4문제로 고난도 문제 연습에 용이합니다.",
    "6문제로 문제 종류 상관없이 역대 고난도 문제들만 모아서 풀어볼 수 있습니다.",
    "14문제로 듣기 도중 풀어내면 좋을 문제들을 연습할 수 있습니다.",
    "45문제 풀 모의고사로 수능형 실전 기출을 골고루 푸실 수 있습니다.",
    "비문학 3지문으로 매일 1회씩 풀면서 실력을 업그레이드 할 수 있습니다.",
    "문학 3지문으로 매일 1회씩 풀면서 실력을 업그레이드 할 수 있습니다.",
    "화작/언매 11문제로 매일 1회씩 풀면서 실력을 업그레이드 할 수 있습니다.",
    "한국사 20문제를 기출 유형에 맞게 랜덤으로 풀 수 있습니다."
  ];
  if (formData.start > formData.end) {
    setFormData({ ...formData, start: 2018, end: 2024 });
    setOn(true);
  }
  return (
    <div className="column2 prb">
      <Nav />
      {isLoading ? (
        <Loading />
      ) : (
        <div className="selector">
          {intId <= 4 && intId >= 1 ? (
            <div className="row2 pb_ct">
              <Link
                to="/problem/1"
                className="pb_bx column2"
                style={{
                  color: intId === 1 ? "#fff" : "#222222",
                  background: intId === 1 ? "#222222" : "#fff",
                }}
              >
                풀모
              </Link>
              <Link
                to="/problem/2"
                className="pb_bx column2"
                style={{
                  color: intId === 2 ? "#fff" : "#222222",
                  background: intId === 2 ? "#222222" : "#fff",
                }}
              >
                하프
              </Link>
              <Link
                to="/problem/3"
                className="pb_bx column2"
                style={{
                  color: intId === 3 ? "#fff" : "#222222",
                  background: intId === 3 ? "#222222" : "#fff",
                }}
              >
                킬러
              </Link>
              <Link
                to="/problem/4"
                className="pb_bx column2"
                style={{
                  color: intId === 4 ? "#fff" : "#222222",
                  background: intId === 4 ? "#222222" : "#fff",
                }}
              >
                어삼쉬사
              </Link>
            </div>
          ) : (
            ""
          )}
          {intId <= 8 && intId >= 5 ? (
            <div className="row2 pb_ct">
              <Link
                to="/problem/5"
                className="pb_bx column2"
                style={{
                  color: intId === 5 ? "#fff" : "#ff7f00",
                  background: intId === 5 ? "#ff7f00" : "#fff",
                }}
              >
                하프
              </Link>
              <Link
                to="/problem/6"
                className="pb_bx column2"
                style={{
                  color: intId === 6 ? "#fff" : "#ff7f00",
                  background: intId === 6 ? "#ff7f00" : "#fff",
                }}
              >
                빈순삽
              </Link>
              <Link
                to="/problem/7"
                className="pb_bx column2"
                style={{
                  color: intId === 7 ? "#fff" : "#ff7f00",
                  background: intId === 7 ? "#ff7f00" : "#fff",
                }}
              >
                최고난도
              </Link>
              <Link
                to="/problem/8"
                className="pb_bx column2"
                style={{
                  color: intId === 8 ? "#fff" : "#ff7f00",
                  background: intId === 8 ? "#ff7f00" : "#fff",
                }}
              >
                시간단축
              </Link>
            </div>
          ) : (
            ""
          )}
          {intId <= 12 && intId >= 9 ? (
            <div className="row2 pb_ct">
              <Link
                to="/problem/9"
                className="pb_bx column2"
                style={{
                  color: intId === 9 ? "#fff" : "#29CC44",
                  background: intId === 9 ? "#29CC44" : "#fff",
                }}
              >
                풀모
              </Link>
              <Link
                to="/problem/10"
                className="pb_bx column2"
                style={{
                  color: intId === 10 ? "#fff" : "#29CC44",
                  background: intId === 10 ? "#29CC44" : "#fff",
                }}
              >
                매3비
              </Link>
              <Link
                to="/problem/11"
                className="pb_bx column2"
                style={{
                  color: intId === 11 ? "#fff" : "#29CC44",
                  background: intId === 11 ? "#29CC44" : "#fff",
                }}
              >
                매3문
              </Link>
              <Link
                to="/problem/12"
                className="pb_bx column2"
                style={{
                  color: intId === 12 ? "#fff" : "#29CC44",
                  background: intId === 12 ? "#29CC44" : "#fff",
                }}
              >
                매3화작
              </Link>
            </div>
          ) : (
            ""
          )}
          {intId === 13 ? (
            <div className="row2 pb_ct">
              <Link
                to="/problem/13"
                className="pb_bx column2"
                style={{
                  color:"#fff",
                  background:"#29CC44",
                  width:'100%'
                }}
              >
                한국사 풀모
              </Link>
            </div>
          ) : (
            ""
          )}
          <form onSubmit={handleSubmit}>
            <div className="column2 pb_st">
              <div className="st_title">
                {txt[id]}
                <input type="hidden" name="type" value={id} />
              </div>
              <div className="st_pt">
                <div className="st_txt">
                  연도
                  <div
                    className="pop"
                    style={{ display: on ? "block" : "None" }}
                  >
                    ※시작년도와 종료년도를 확인해 주세요.
                  </div>
                </div>
                <Dropdown
                  items={intId <= 4 && intId >= 1 ? years1 : years2}
                  val={formData.start}
                  valChange={setFormData}
                  name="start"
                  data={formData}
                />
                <Dropdown
                  items={intId <= 4 && intId >= 1 ? years1 : years2}
                  val={formData.end}
                  valChange={setFormData}
                  name="end"
                  data={formData}
                />
              </div>
              {intId <= 4 && intId >= 1 ? (
                <div className="st_ct row2">
                  <div className="st_txt">선택</div>
                  <CheckboxGroup
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    nameList={nameList}
                  />
                </div>
              ) : (
                <div style={{ color: "#666", fontSize: "12px" }}>
                  ※평가원 문제만 제공됩니다.
                </div>
              )}
              {intId === 10 || intId === 11 ? (
                <div className="st_ct row2">
                  <div className="st_txt">선택</div>
                  <CheckboxGroup
                    checkedItems={checkedItems}
                    setCheckedItems={setCheckedItems}
                    nameList={nameList}
                  />
                </div>
              ) : (
                ""
              )}
              {id === "1" ? (
                <div className="st_ct row2">
                  <div className="st_txt">과목</div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      id="cal"
                      className="chbx"
                      name="select"
                      value="cal"
                      checked={formData.select === "cal"}
                      onClick={() => handleChange("select", "cal")}
                    />
                    <label htmlFor="cal" className="chbx_txts">
                      미적
                    </label>
                  </div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      id="prob"
                      className="chbx"
                      name="select"
                      value="prob"
                      checked={formData.select === "prob"}
                      onClick={() => handleChange("select", "prob")}
                    />
                    <label htmlFor="prob" className="chbx_txts">
                      확통
                    </label>
                  </div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      id="vec"
                      className="chbx"
                      name="select"
                      value="vec"
                      checked={formData.select === "vec"}
                      onClick={() => handleChange("select", "vec")}
                    />
                    <label htmlFor="vec" className="chbx_txts">
                      기하
                    </label>
                  </div>
                </div>
              ) : id === "12" || id === "9" ? (
                <div className="st_ct row2">
                  <div className="st_txt">과목</div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      className="chbx"
                      name="select"
                      value="media"
                      checked={formData.select === "media"}
                      onClick={() => handleChange("select", "media")}
                    />
                    <label htmlFor="media" className="chbx_txts">
                      언매
                    </label>
                  </div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      className="chbx"
                      name="select"
                      value="talk"
                      checked={formData.select === "talk"}
                      onClick={() => handleChange("select", "talk")}
                    />
                    <label htmlFor="talk" className="chbx_txts">
                      화작
                    </label>
                  </div>
                </div>
              ) : intId <= 4 && intId >= 2 ? (
                <div className="st_ct row2">
                  <div className="st_txt">과목</div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      className="chbx"
                      name="select"
                      value="m1"
                      checked={formData.select === "m1"}
                      onClick={() => handleChange("select", "m1")}
                    />
                    <label htmlFor="m1" className="chbx_txts">
                      수1
                    </label>
                  </div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      className="chbx"
                      name="select"
                      value="m2"
                      checked={formData.select === "m2"}
                      onClick={() => handleChange("select", "m2")}
                    />
                    <label htmlFor="m2" className="chbx_txts">
                      수2
                    </label>
                  </div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      className="chbx"
                      name="select"
                      value="cal"
                      checked={formData.select === "cal"}
                      onClick={() => handleChange("select", "cal")}
                    />
                    <label htmlFor="cal" className="chbx_txts">
                      미적
                    </label>
                  </div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      className="chbx"
                      name="select"
                      value="prob"
                      checked={formData.select === "prob"}
                      onClick={() => handleChange("select", "prob")}
                    />
                    <label htmlFor="prob" className="chbx_txts">
                      확통
                    </label>
                  </div>
                  <div className="chbx_ct row2">
                    <input
                      type="radio"
                      className="chbx"
                      name="select"
                      value="vec"
                      checked={formData.select === "vec"}
                      onClick={() => handleChange("select", "vec")}
                    />
                    <label htmlFor="vec" className="chbx_txts">
                      기하
                    </label>
                  </div>
                </div>
              ) : (
                ""
              )}
              {user.isLogin ? (
                <div className="row2 st_ct">
                  <div className="st_txt">
                    문제 중복 제거
                    <div className="pop">
                      ※로그인 후 풀었던 문제만 중복 제거됩니다.
                    </div>
                  </div>

                  <input
                    type="checkbox"
                    className="dup_chx"
                    name="dup"
                    onChange={dupClick}
                    checked={formData.dup}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="st_ct btn_ct row2">
                <button
                  type="submit"
                  className="st_btn column2"
                  style={{
                    background:
                      intId >= 1 && intId <= 4
                        ? "#222"
                        : intId <= 8 && intId >= 5
                          ? "#ff7f00"
                          : "#29CC44",
                  }}
                >
                  만들기
                </button>
              </div>
            </div>
          </form>
          <div style={{ color: "rgb(105,105,105)", fontSize: "12px" }}>
            ※제작된 문제의 최대 이용 기간은 1년입니다.
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Problem;
