import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../UserProvider";
import Nav from "../component/Nav";
import "./Pricing.css";
import Card from "../component/Card";
import Popup from "../component/Popup";
import Footer from "../component/Footer";

function Pricing() {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [goodsName, setGoodsName] = useState("");
  const [price, setPrice] = useState(0);
  const { user } = useContext(UserContext);
  const [refund, setRefund] = useState(false);
  const history = useNavigate();
  const handlePrice = (goodsName, price) => {
    if (user.isLogin) {
      setGoodsName(goodsName);
      setPrice(price);
      setIsOpen2(true);
    } else {
      window.alert("로그인 후 이용해 주세요.");
      history("/login");
    }
  };
  const handleRefund = () => {
    if (user.isLogin) {
      setIsOpen(true);
    } else {
      window.alert("로그인 후 이용해 주세요.");
      history("/login");
    }
  };
  return (
    <div>
      <div className="price_ct">
        <Nav />
        <div className="price_tct">
          <div className="cd_tt">보유 크레딧 : </div>
          <div className="cd_ct">
            <img src="/icon/credit.png" className="price_credit" alt="credit" />
            {user.credit}
          </div>
        </div>
        <div className="prc_ct">
          <div className="prc_bx">
            <div className="pc_txt">구독형</div>
            <div className="pc_txt">1개월(30일)</div>
            <div className="pc_txt column1">
              <del className="pr_sale">&#8361;8,900</del>월 &#8361;6,900
            </div>
            <div onClick={handleRefund} className="pc_btn">
              →
            </div>
          </div>
          <div className="prc_bx">
            <div className="pc_txt">일회성 결제</div>
            <div className="pc_txt">1개월(30일)</div>
            <div className="pc_txt column1">&#8361;8,900</div>
            <div
              onClick={() => handlePrice("기출조각 1달 이용권", 8900)}
              className="pc_btn"
            >
              →
            </div>
          </div>
          <div className="prc_bx">
            <div className="pc_txt">일회성 결제</div>
            <div className="pc_txt">수능까지 무제한(~11.14)</div>
            <div className="pc_txt column1">
              <del className="pr_sale">&#8361;8,900</del>
              &#8361;3,900
              <div className="sale_per">56%할인</div>
            </div>
            <div
              onClick={() => handlePrice("수능까지 무제한(~11.14)", 3900)}
              className="pc_btn"
            >
              →
            </div>
          </div>
          <div style={{ color: "rgb(105,105,105)", fontSize: "12px" }}>
            ※회원권 구매 시 국어, 영어, 수학 모두 무제한 이용 가능합니다.
          </div>
          <div className="refund_intro column1">
            <div className="refund_txt" onClick={() => setRefund(!refund)}>
              환불 규정 안내 →
            </div>
            <div
              className="refund_ser"
              style={{ display: refund ? "block" : "none" }}
            >
              <p>
                1. 회원은 회사에 환불을 요구할 수 있습니다. 환불은 회사가
                안내하는 정책 및 방법에 따라 진행됩니다.
              </p>
              <p>2. 회사는 다음 각 호의 방식으로 환불을 진행합니다.</p>
              <p>
                정기 결제 회원 : 환불은 서비스를 이용한 일 수를 제외하고 일할
                계산으로 진행됩니다. 월 기준은 30일이고 월 이용료를 30으로 나눈
                금액을 말합니다. 결제 시간으로부터 24시간 이내 사용자는 전액
                환불 처리되며, 결제 시간으로부터 24시간 이후부터 15일까지는 남은
                일 수에 대한 일할 계산으로 환불 처리됩니다. 16일 이후 30일 이내
                사용자는 환불일 불가능합니다. 일할 계산은 카드 수수료와 위약금을
                포함한 결제 금액의 20%를 제외한 금액이며, 남은 일수에 대한 일할
                계산으로 환불 처리 됩니다.
              </p>
              <p>계산 공식 : [전체 결제 금액 * 0.8/30*남은 일수]</p>
              <p>
                연간(수능형) 결제 회원 : 수능형은 결제일로부터 수능일까지 월
                결제로 계산하여 하루라도 이용한 월은 제외하고 남은 일수를 전체
                일수로 나눈 비율만큼의 20%를 환불합니다.
              </p>
            </div>
          </div>
        </div>
        <Card isOpen={isOpen} setIsOpen={setIsOpen} />
        <Popup
          isOpen={isOpen2}
          setIsOpen={setIsOpen2}
          goodsName={goodsName}
          price={price}
        />
        <Footer/>
      </div>
    </div>
  );
}

export default Pricing;
